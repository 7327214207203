"use client";

import { Fragment, memo, useState } from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import useMediaQuery from "@mui/material/useMediaQuery";
import Home from "icons/Home";
import User2 from "icons/User2";
import CategoryOutlined from "icons/CategoryOutline";
import ShoppingBagOutlined from "icons/ShoppingBagOutlined";
import useHeader from "../../components/header/hooks/use-header";
import useCart from "hooks/useCart";
import Drawer from "@mui/material/Drawer";
import Scrollbar from "components/scrollbar";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import { H1, H2, H3 } from "../../components/Typography";
import { Button } from "@mui/material"
import { iconStyle, StyledBox, StyledNavLink, Wrapper } from "./styles";
import DialogDrawer from "../../components/header/components/dialog-drawer";
import { renderLevels } from "components/navbar/mobile-menu/render-levels";
import { useCategories } from "contexts/CategoryContext";
import { FlexBox } from "components/flex-box";
import SearchInput from "components/search-box/search-input"
import MobileHeader2 from "components/header/components/mobile-header-2";
import {useUser} from 'contexts/UserContext'


const MobileNavigationBar2 = ({ children }) => {
  const { cart } = useCart();
  const {
    dialogOpen,
    sidenavOpen,
    searchBarOpen,
    toggleDialog,
    toggleSidenav,
    toggleSearchBar,
  } = useHeader();
  const {user} = useUser();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleClose = () => setOpenDrawer(false);
  const shopCategories = useCategories();

  const DOWN_900 = useMediaQuery((theme) => theme.breakpoints.down(900));

  const handleDrawerClose = () => setOpenDrawer(false);
  const handleDrawerToggle = () => setOpenDrawer((state) => !state);

  const getTotalItems = () => cart.reduce((acc, item) => acc + item.qty, 0);

  const list = [
    {
      title: "Home",
      Icon: Home,
      href: "/",
    },
    {
      title: "Category",
      Icon: CategoryOutlined,
      onClick: handleDrawerToggle,
    },
    {
      title: "Cart",
      Icon: ShoppingBagOutlined,
      onClick: toggleSidenav,
    },
    {
      title: "Account",
      Icon: User2,
      // onClick: toggleDialog,
      href: "/profile"
    },
  ];

  if (DOWN_900) {
    return (
      <Box position="relative" display="flex" flexDirection="column">
        <Drawer anchor="left" open={openDrawer} onClose={handleClose} sx={{ zIndex: 15001 }}>
          <Box width="100vw" height="100%" position="relative">
            <Scrollbar autoHide={false} sx={{ height: "100vh" }}>
              <Box px={5} py={8} maxWidth={500} margin="auto" position="relative" height="100%">
                {/* CLOSE BUTTON */}
                <IconButton onClick={handleClose} sx={{ position: "absolute", right: 30, top: 15 }}>
                  <Clear fontSize="small" />
                </IconButton>

                {/* MULTI LEVEL MENU RENDER */}
                <MobileHeader2 />
                {renderLevels(shopCategories, handleClose)}
              </Box>
            </Scrollbar>
          </Box>
        </Drawer>

        <Wrapper>
          {list.map(({ Icon, title, href, onClick }) => {
            const ICON = (
              <Badge
                badgeContent={title === "Cart" ? getTotalItems() : null}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {title === "Account" && user !== null ? (
                  <Badge
                    badgeContent="✓"
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Icon sx={iconStyle} fontSize="small" />
                  </Badge>
                ) : (
                  <Icon sx={iconStyle} fontSize="small" />
                )}
              </Badge>
            );

            const CONTENT = (
              <Fragment>
                {ICON}
                {title}
              </Fragment>
            );

            return href ? (
              <StyledNavLink key={title} href={href}>
                {CONTENT}
              </StyledNavLink>
            ) : (
              <StyledBox key={title} onClick={onClick}>
                {CONTENT}
              </StyledBox>
            );
          })}
        </Wrapper>

        {/* Include the DialogDrawer component for sidenav and dialog */}
        <DialogDrawer
          dialogOpen={dialogOpen}
          sidenavOpen={sidenavOpen}
          toggleDialog={toggleDialog}
          toggleSidenav={toggleSidenav}
        />
      </Box>
    );
  }

  return null;
};

export default memo(MobileNavigationBar2);