import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import PersonOutline from "@mui/icons-material/PersonOutline";
import ShoppingBagOutlined from "icons/ShoppingBagOutlined";
import useCart from "hooks/useCart";
import Link from "next/link";
import {useUser} from 'contexts/UserContext'
export default function LoginCartButtons({ toggleDialog, toggleSidenav }) {
  const { cart } = useCart();
  const { user } = useUser();

  const ICON_COLOR = {
    color: "grey.600",
  };
  const getTotalItems = () => {
    return cart.reduce((acc, item) => acc + item.qty, 0);
  };

    return (
    <div>
      <Link href="/profile">
        {user ? (
          <Badge
            badgeContent="✓"
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <IconButton>
              <PersonOutline sx={ICON_COLOR} />
            </IconButton>
          </Badge>
        ) : (
          <IconButton>
            <PersonOutline sx={ICON_COLOR} />
          </IconButton>
        )}
      </Link>

      <Badge
        badgeContent={getTotalItems()}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <IconButton onClick={toggleSidenav}>
          <ShoppingBagOutlined sx={ICON_COLOR} />
        </IconButton>
      </Badge>
    </div>
  );
}