
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";


const wooApi = new WooCommerceRestApi({
  url: process.env.NEXT_PUBLIC_BASEURL, 
  consumerKey: process.env.NEXT_PUBLIC_KEY,
  consumerSecret: process.env.NEXT_PUBLIC_SECRET,
  version: "wc/v3"
});


export default wooApi;