// LOCAL CUSTOM COMPONENTS
import OfferBanner from "./banner";
import ColumnList from "./column-list";
import CategoryMenuItem from "../category-list-item"; // STYLED COMPONENT
import MegaMenu1 from "../mega-menu/mega-menu-1";

import { StyledRoot } from "./styles"; // DATA TYPE

// =======================================================================
export default function MegaMenu2({ data }) {
  return (
    <StyledRoot elevation={2}>
      {data.map((item) => (
        <CategoryMenuItem
          href={item.href}
          icon={item.icon}
          key={item.title}
          title={item.title}
          caret={false}
          // render={
          //   item.children?.length ? (
          //     <ColumnList minWidth={200} list={item.children}>
          //       {/* <OfferBanner /> */}
          //     </ColumnList>
          //   ) : null
          // }
        />
      ))}
    </StyledRoot>
  );
}


// HALF LOGIC TO INTRODUCE RECURSIVE MENUS
// export default function MegaMenu2({ data }) {
//   return (
//     <div>
//       {data.map((item) => (
//         <CategoryMenuItem
//           key={item.title}
//           href={item.href}
//           title={item.title}
//           caret={!!item.children && item.children.length > 0}
//           render={
//             item.children && item.children.length > 0 ? (
//               <MegaMenu2 data={item.children} />
//             ) : null
//           }
//         />
//       ))}
//     </div>
//   );
// }
