import MenuItem from "@mui/material/MenuItem";
// MUI ICON COMPONENTS
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

// GLOBAL CUSTOM COMPONENTS
import { NavLink } from "components/nav-link";
import { FlexBox } from "components/flex-box";
import BazaarCard from "components/BazaarCard";

// LOCAL CUSTOM COMPONENTS
import MegaMenu from "../mega-menu";
import NavItemChild from "./nav-item-child";
import CategoryBasedMenu from "../category-based-menu";

import { useCategories } from "contexts/CategoryContext"; // Real Data

// NAVIGATION DATA LIST
import { StyledNavLink, NAV_LINK_STYLES, ChildNavListWrapper } from "../styles";


export default function NavigationList() {
  // const shop = useCategories();

 
  const nav = [

    {
      title: "My Account",
      megaMenu: false,
      megaMenuWithSub: false,
      children: [
        {
          title: "Dashboard",
          url: "/profile",
        },
        {
          title: "Orders",
          url: "/profile",
        },
        {
          title: "Log out",
          url: "/logout",
        },
      ],
    },{
      title: "Info",
      megaMenu: false,
      megaMenuWithSub: false,
      children: [
        {
          title: "About us",
          url: "/info/about",
        },
        {
          title: "Returns",
          url: "/info/returns",
        },
        {
          title: "Shipping",
          url: "/info/shipping",
        },
        {
          title: "Warranties",
          url: "/info/warranty",
        },
        {
          title: "FAQ",
          url: "/info/faq",
        },
        {
          title: "Privacy policy",
          url: "/info/privacy",
        },
        {
          title: "Terms & Conditions",
          url: "/info/terms",
        },
      ],
    },
    {
      title: "Help",
      megaMenu: false,
      megaMenuWithSub: false,
      children: [
        {
          title: "Contact",
          url: "/info/contact",
        },
        {
          title: "Returns",
          url: "/info/returns",
        },
        {
          title: "Warranties",
          url: "/info/warranty",
        },
        {
          title: "FAQ",
          url: "/info/faq",
        },
      ],
    },
    {
      title: "Reviews & Testimonials",
      url: "/info/reviews",
      megaMenu: false,
      megaMenuWithSub: false,
      
    },
  ];

  const renderNestedNav = (list = [], isRoot = false) => {
    return list.map((nav) => {
      if (isRoot) {
        // SHOW MEGA MENU
        if (nav.megaMenu) {
          return (
            <MegaMenu key={nav.title} title={nav.title} menuList={nav.children} />
          );
        }
        // SHOW MEGA MENU WITH SUB ITEMS
        if (nav.megaMenuWithSub) {
          return (
            <CategoryBasedMenu
              key={nav.title}
              title={nav.title}
              menuList={nav.children}
            />
          );
        }

        if (!nav.children) {
          return (
            <StyledNavLink href={nav.url} key={nav.title}>
              {nav.title}
            </StyledNavLink>
          );
        }

        else if (nav.children) {
          return (
            
            <FlexBox
              key={nav.title}
              alignItems="center"
              position="relative"
              flexDirection="column"
              sx={{
                "&:hover": {
                  "& > .child-nav-item": {
                    display: "block",
                  },
                },
              }
            }
            >
              <FlexBox alignItems="flex-end" gap={0.3} sx={NAV_LINK_STYLES}>
                {nav.title}{" "}
                <KeyboardArrowDown
                  sx={{
                    color: "grey.500",
                    fontSize: "1.1rem",
                  }}
                />
              </FlexBox>

              <ChildNavListWrapper className="child-nav-item">
                <BazaarCard
                  elevation={3}
                  sx={{
                    mt: 2.5,
                    py: 1,
                    minWidth: 100,
                  }}
                >
                  {renderNestedNav(nav.children)}
                </BazaarCard>
              </ChildNavListWrapper>
            </FlexBox>
          );
        }
      } else {
        if (!nav.children) {
          return (
            <NavLink href={nav.url} key={nav.title}>
              <MenuItem>{nav.title}</MenuItem>
            </NavLink>
          );
        }

        if (nav.children) {
          return (
            <NavItemChild nav={nav} key={nav.title}>
              {renderNestedNav(nav.children)}
            </NavItemChild>
          );
        }
      }
    });
  };

  return (
    <FlexBox gap={4}>
      {renderNestedNav(nav, true)}
    </FlexBox>
  );
}
