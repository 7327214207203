// LOCAL CUSTOM COMPONENTS
import MegaMenu1 from "../mega-menu/mega-menu-1";
import MegaMenu2 from "../mega-menu/mega-menu-2";
import CategoryListItem from "../category-list-item"; // NAVIGATION DATA
import { StyledRoot } from "./styles"; // PROPS TYPE
import { useCategories } from 'contexts/CategoryContext'

const componentMapping = {
  MegaMenu2: MegaMenu2,
};

export default function CategoryList({
  open,
  position = "absolute"
}) {
  const categories = useCategories();  // Use the context to get categories

  return <StyledRoot open={open} position={position}>
      {categories.map(item => {
      const {
        href,
        title,
        children,
        component,
        offer
      } = item;


      // const MegaMenuMap = componentMapping[component];  // Get the correct component based on the string identifier

      // const MegaMenu = component === MegaMenu1.name ? MegaMenu1 : MegaMenu2;
      // const MegaMenu = MegaMenuMap === MegaMenu2 ? MegaMenu2 : MegaMenu2;

      return <CategoryListItem 
        key={title} 
        href={href} 
      // icon={icon} 
        title={title} 
        caret={!!children} 
        render={children ? <MegaMenu2 data={children} banner={offer} /> : null} />;
    })}
    </StyledRoot>;
}
