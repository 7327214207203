"use client";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useSearch from "./hooks/use-search";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"; // Corrected Import
import { useRouter } from "next/navigation";

export default function SearchInput({ toggleSearchBar }) {
  // const { handleSearch } = useSearch();
  const [searchText, setSearchText] = useState("");
  const router = useRouter();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      router.push(`/products/search/${encodeURIComponent(searchText)}`);
    }
  };

  const handleSearchButtonClick = () => {
    router.push(`/products/search/${encodeURIComponent(searchText)}`);
    if (toggleSearchBar) {
      toggleSearchBar();
    }
  };

  return (
    <Box position="relative" flex="1" maxWidth="670px" mx="auto" paddingLeft="10px" paddingRight="10px">
      {/* <TextField 
        fullWidth 
        variant="outlined" 
        placeholder=" Searching for..." 
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: <SearchOutlinedIcon fontSize="small" onClick={handleSearchButtonClick}
          />
        }}
      /> */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          style: { fontSize: 16 }, // Ensures the font size is 16px or more
          endAdornment: (
            <SearchOutlinedIcon
              fontSize="small"
              onClick={handleSearchButtonClick}
            />
          ),
        }}
        inputProps={{
          style: { fontSize: 16 }, // Also ensure the actual input has font size set properly
        }}
      />
    </Box>
  );
}
