

import Link from "next/link"; // MUI
import Box from "@mui/material/Box"; // GLOBAL CUSTOM COMPONENTS
import { FlexBox } from "components/flex-box";
import { NavLink } from "components/nav-link"; // STYLED COMPONENTS
import { StyledRoot } from "./styles"; // DATA TYPES


export default function ColumnList({ list, minWidth = 760 }) {
  return (
    <StyledRoot elevation={2} sx={{ minWidth }}>
      <FlexBox px={2.5} flexDirection="column">
        <Box flex="1 1 0">
          {list.map((item, index) => (
            <Box key={index}>
              <NavLink className="title-link" href={item.href}>
                {item.title}
              </NavLink>
              {item.children && (
                <div>
                  {item.children.map((sub, subIndex) => (
                    <NavLink className="child-link" href={sub.href} key={subIndex}>
                      {sub.title}
                    </NavLink>
                  ))}
                </div>
              )}
            </Box>
          ))}
        </Box>
      </FlexBox>
    </StyledRoot>
  );
}

// TO MAKE RECUSRIVE LEAF NODE LIST (DOESNT LOOK GREAT, USE THE FOLLOWING:)

// import Link from "next/link";
// import Box from "@mui/material/Box";
// import { FlexBox } from "components/flex-box";
// import { NavLink } from "components/nav-link";
// import { StyledRoot } from "./styles";

// const ColumnList = ({ list, minWidth = 760 }) => {
//   // Render each item in the list
//   const renderItem = (item) => (
//     <Box key={item.slug}>
//       <NavLink className="title-link" href={item.href}>
//         {item.title}
//       </NavLink>
//       {/* Check if this item has children and recursively render them */}
//       {item.children && item.children.length > 0 && (
//         <div style={{ marginLeft: '20px' }}>
//           {item.children.map(renderItem)}
//         </div>
//       )}
//     </Box>
//   );

//   return (
//     <StyledRoot elevation={2} sx={{ minWidth }}>
//       <FlexBox px={2.5} flexDirection="column">
//         {list.map(renderItem)}
//       </FlexBox>
//     </StyledRoot>
//   );
// };

// export default ColumnList;
