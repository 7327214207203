'use client'


import { useEffect, useRef, useState } from "react";
import wooApi from 'lib/wooInstance';

export default function useSearch() {
  const [searchText, setSearchText] = useState('');
  const [resultList, setResultList] = useState([]);
  const debounceRef = useRef(null);

  useEffect(() => {
    const fetchProducts = () => {
      if (searchText.trim()) {
        // IIFE to handle the async operation
        (async () => {
          try {
            // Assuming wooApi.get returns a response in the format { data: ... }
            const response = await wooApi.get(`products?search=${encodeURIComponent(searchText)}&per_page=100`);
            if (response && response.data) {
              setResultList(response.data);
            } else {
              throw new Error('Failed to fetch products');
            }
          } catch (error) {
            console.error('Error fetching products:', error);
            setResultList([]);
          }
        })();
      } else {
        setResultList([]); // Clear results if search text is cleared
      }
    };

    // Debounce logic
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(fetchProducts, 300);

    return () => clearTimeout(debounceRef.current);
  }, [searchText]); // Depend on searchText

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

/// garbage letftovers that break build when i delete
  const handleCategoryChange = cat => () => {
    setCategory(cat.value);
    setCategoryTitle(cat.title);
  }; 
////

  return {
    handleSearch,
    resultList,
    handleCategoryChange
  };
}


// import { useEffect, useRef, useState, useTransition } from "react";
// import api from "utils/__api__/products";
// export default function useSearch() {
//   const parentRef = useRef();
//   const [_, startTransition] = useTransition();
//   const [category, setCategory] = useState("*");
//   const [resultList, setResultList] = useState([]);
//   const [categoryTitle, setCategoryTitle] = useState("All Categories"); // HANDLE CHANGE THE CATEGORY

//   const handleCategoryChange = cat => () => {
//     setCategory(cat.value);
//     setCategoryTitle(cat.title);
//   }; // FETCH PRODUCTS VIA API


//   const getProducts = async (searchText, category) => {
//     const data = await api.searchProducts(searchText, category);
//     setResultList(data);
//   };

//   const handleSearch = e => {
//     startTransition(() => {
//       const value = e.target?.value;
//       if (!value) setResultList([]);else if (value && category !== "*") getProducts(value, category);else getProducts(value);
//     });
//   };

//   const handleDocumentClick = () => setResultList([]);

//   useEffect(() => {
//     window.addEventListener("click", handleDocumentClick);
//     return () => window.removeEventListener("click", handleDocumentClick);
//   }, []);
//   return {
//     category,
//     parentRef,
//     resultList,
//     handleSearch,
//     categoryTitle,
//     handleCategoryChange
//   };
// }