import styled from "@mui/material/styles/styled";
export const Wrapper = styled("div")(({
  theme
}) => ({
  "& .category-dropdown-link": {
    position: 'relative',
    height: 40,
    display: "flex",
    minWidth: "278px",
    cursor: "pointer",
    whiteSpace: "pre",
    padding: "0px 1rem",
    alignItems: "center",
    transition: "all 300ms ease-in-out",
    ".title": {
      flexGrow: 1,
      paddingLeft: "0.75rem"
    }
  },
  ":hover": {
    color: theme.palette.primary.main,
    background: theme.palette.action.hover,
    "& > .mega-menu": {
      display: "block"
    }
  },
  ".mega-menu": {
    // top: 0,  // This will place child menus at the top of the page
    zIndex: 99,
    marginTop: "-2.5rem",
    left: "100%", // Allows child menu to overlap for easy navigation
    right: "auto",
    display: "none",
    position: "absolute"
  },
  ...(theme.direction === "rtl" && {
    ".caret-icon": {
      rotate: "180deg"
    }
  })
}));